<template>
  <div class="personal-wrapper">
    <Breadcrumbs>
      <template slot="action-buttons">
        <v-btn
          v-show="isShow"
          class="pl-5 pr-5"
          depressed
          color="primary"
          @click="saveData()"
          >保存</v-btn
        >
      </template>
    </Breadcrumbs>
    <div class="setting_wrapper">
      <TabList
        class="tablist"
        @tabEvent="tabEvent($event)"
        :tabList="tabList"
      />
      <div>
        <component :is="curCom" :tabData="tabData" ref="component" />
      </div>
    </div>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import Breadcrumbs from "@/components/Breadcrumbs";
import TabList from "@/components/TabList";
import SystemKeyAlgo from "./component/SystemKeyAlgo";
import PasswordPolicy from "./component/PasswordPolicy";
import LoginSecurity from "./component/LoginSecurity";
import LogSetting from "./component/LogSetting";
import SessionManage from "./component/SessionManage";
import SmsSet from "./component/SmsSet";

const COMPONENTS = [
  SystemKeyAlgo,
  PasswordPolicy,
  LoginSecurity,
  SessionManage,
  SmsSet,
  LogSetting
];

export default {
  name: "Personalization",
  data() {
    return {
      isShow: false,
      tabId: 0,
      tabList: [
        {
          id: 0,
          name: "系统密钥算法"
        },
        {
          id: 1,
          name: "密码策略"
        },
        {
          id: 2,
          name: "登录安全"
        },
        {
          id: 3,
          name: "会话管理"
        },
        {
          id: 4,
          name: "短信配置"
        },
        {
          id: 5,
          name: "日志设置"
        }
      ],
      curCom: "SystemKeyAlgo",
      tabData: {}
    };
  },
  created() {
    this.getData();
    bus.$off("refreshSecurity").$on("refreshSecurity", () => {
      this.getData();
    });
  },
  methods: {
    tabEvent(id) {
      this.tabId = id;
      if (id !== 0) {
        this.isShow = true;
      }
    },
    saveData() {
      if (this.tabId === 1) {
        this.$refs.component.modifyPwdPolicy();
      } else if (this.tabId === 2) {
        this.$refs.component.modifyLoginSecurity();
      } else if (this.tabId === 3) {
        this.$refs.component.modifySessionManage();
      } else if (this.tabId === 4) {
        this.$refs.component.modifySmsSet();
      } else if (this.tabId === 5) {
        this.$refs.component.modifySyslog();
      }
    },
    getData() {
      return this.$http
        .get(`api/settings/security`)
        .delegateTo(api_request)
        .then(data => {
          this.tabData = data;
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  watch: {
    tabId(v) {
      this.curCom = COMPONENTS[v];
    }
  },
  components: {
    Breadcrumbs,
    TabList,
    SystemKeyAlgo,
    PasswordPolicy,
    LoginSecurity,
    LogSetting,
    SessionManage,
    SmsSet
  }
};
</script>
<style lang="less" scoped>
.personal-wrapper {
  .setting_wrapper {
    margin: 12px 12px 0 24px;
  }
}
</style>
