<template>
  <div>
    <v-form class="mt-4" :model="form" v-model="valid" ref="form">
      <v-row align="center" style="border-bottom:none!important">
        <v-col cols="1">
          <v-subheader>syslog服务器</v-subheader>
        </v-col>

        <v-col cols="6">
          <v-textarea
            rows="1"
            auto-grow
            v-model="syslog_servers"
            persistent-hint
            hint="若填写多个服务器，需用逗号隔开如：syslog://a.com,syslog://b.com"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "LogoSetting",
  data() {
    return {
      valid: true,
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"]
      },
      syslog_servers: ""
    };
  },
  created() {
    this.getSyslog();
  },
  methods: {
    getSyslog() {
      return this.$http
        .get(`api/settings/logger`)
        .delegateTo(api_request)
        .then(res => {
          let { syslog_servers } = res;

          this.syslog_servers = syslog_servers ? syslog_servers.join(",") : "";
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    modifySyslog() {
      if (this.$refs.form.validate()) {
        let payload = {
          syslog_servers: this.syslog_servers.split(",")
        };
        return this.$http
          .patch(`api/settings/syslog`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$emit("sonVal", false);
            return this.$snackbar.showMessage({
              content: `保存成功`,
              isError: false
            });
          })
          .catch(({ code, message }) => {
            throw `保存失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-subheader {
  color: rgb(44, 62, 80);
}

/deep/.v-input__slot {
  padding-left: 10px;
}

/deep/ .v-label {
  font-size: 14px;
  color: rgb(44, 62, 80);
}
/deep/ .v-select__selections {
  font-size: 14px;
}

/deep/ .v-text-field__slot {
  font-size: 14px;
}

/deep/ .v-input__slot {
  padding-left: 5px;
}

.slider-item {
  /deep/ .v-input__slot {
    padding-left: 0px;
  }
}

.hint-style {
  padding-left: 30%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
