<template>
  <div>
    <v-form :model="form" v-model="valid" ref="form">
      <v-row style="border-bottom:none!important;" align="center" class="mt-2">
        <v-col cols="1">
          <v-subheader>加密服务</v-subheader>
        </v-col>

        <v-col cols="6">
          <span style="font-size:14px;">{{ encryptionService }}</span>
        </v-col>
      </v-row>

      <v-row style="border-bottom:none!important;margin-top:0;" align="center">
        <v-col cols="1">
          <v-subheader>加密算法</v-subheader>
        </v-col>

        <v-col cols="6">
          <span style="font-size:14px;">{{ encryptionArith }}</span>
        </v-col>
      </v-row>
      <v-row style="border-bottom:none!important;margin-top:0;" align="center">
        <v-col cols="1">
          <v-subheader>描述</v-subheader>
        </v-col>

        <v-col cols="6">
          <span style="font-size:14px;">{{ description }}</span>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "SetLogin",
  data() {
    let regUrl = value => {
      let reg = /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*$/;
      return reg.test(value);
    };

    return {
      valid: true,
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        regUrl: [v => regUrl(v) || "不符合规范"]
      },
      encryptionService: "系统默认服务",
      encryptionArith: "AES/ECB/PKCS5Padding",
      description: "密钥类型AES, 密钥长度128"
    };
  },
  methods: {
    getSmsSet() {
      return this.$http
        .get(`api/settings/sms`)
        .delegateTo(api_request)
        .then(({ sms_config }) => {
          if (sms_config) {
            let { encryptionArith, description } = sms_config;
            this.encryptionArith = encryptionArith;
            this.description = description;
          }
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    getUrl() {
      return this.$http
        .get(`api/system_config`)
        .delegateTo(api_request)
        .then(({ encryptionService }) => {
          this.encryptionService = encryptionService;
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    modifySmsSet() {
      if (this.$refs.form.validate()) {
        let payload = {
          settings: {
            encryptionService: this.encryptionService,
            sms_config: {
              encryptionArith: this.encryptionArith,
              description: this.description
            }
          }
        };

        return this.$http
          .patch(`api/setting/sms`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$emit("sonVal", false);
            return this.$snackbar.showMessage({
              content: `保存成功`,
              isError: false
            });
          })
          .catch(({ code, message }) => {
            throw `保存失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    }
  },
  computed: {
    allVals() {
      const { encryptionService, encryptionArith, description } = this;
      return {
        encryptionService,
        encryptionArith,
        description
      };
    }
  },
  watch: {
    allVals(newVal, oldVal) {
      if (
        oldVal.encryptionService !== newVal.encryptionService ||
        oldVal.encryptionArith !== newVal.encryptionArith ||
        oldVal.description !== newVal.description
      ) {
        this.$emit("sonVal", true);
      }
    }
  },
  destroyed() {
    this.$emit("sonVal", false);
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-subheader {
  color: rgb(44, 62, 80);
}

/deep/.v-input__slot {
  padding-left: 10px;
}

/deep/ .v-label {
  color: rgb(44, 62, 80);
}
</style>
