<template>
  <div>
    <v-form :model="form" v-model="valid" ref="form">
      <v-row style="border-bottom:none!important;" class="align-start mt-0">
        <v-col cols="1" class="mt-2">
          <v-subheader>密码强度</v-subheader>
        </v-col>

        <v-col cols="6">
          <v-radio-group
            v-model="password_strength"
            @change="password_strengthSel(password_strength)"
          >
            <v-radio
              label="弱：必须包含小写字母、数字、特殊字符各一位，长度大于6位，小于20位"
              value="SIMPLE"
            ></v-radio>
            <v-radio
              label="中：包含小写字母、数字、特殊字符至少两种以上，长度大于8位，小于20位"
              value="MEDIUM"
            ></v-radio>
            <v-radio
              label="强：包含大写字母、小写字母、数字、特殊字符至少三种以上，长度大于8位，小于20位"
              value="STRONG"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row no-gutters dense style="margin-top:0;" class="align-center">
        <v-col cols="1">
          <v-subheader> 禁止弱口令</v-subheader>
        </v-col>
        <v-col cols="6" class="d-flex">
          <v-checkbox
            v-model="reject_weak_password"
            @change="rejectPwdSel(reject_weak_password)"
            label="勾选后，将禁止使用弱口令的用户登录。系统内置了互联网经常使用的10万条弱口令，如需要，您可以在以下输入框内填写自定义弱口令"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters dense v-if="reject_weak_password">
        <v-col cols="1" class="mt-0"
          ><v-subheader style="height: 14px !important;">
            自定义弱口令集</v-subheader
          ></v-col
        >
        <v-col cols="6" class="ml-3">
          <v-textarea
            outlined
            v-model="custom_weak_passwords"
            placeholder="自定义弱口令,若存在多个弱口令，请逐一换行输入"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row no-gutters dense style="margin-top:0;" align="center" v-if="0">
        <v-col cols="1">
          <v-subheader> 强制改密</v-subheader>
        </v-col>
        <v-col cols="6" class="d-flex">
          <v-checkbox
            v-model="session_expire_at_browser_close"
            @change="expireBrowserSel(session_expire_at_browser_close)"
            label="勾选后，用户首次登录必须强制更新密码才能进入系统。"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "SetLogin",
  props: ["tabData"],
  data() {
    return {
      valid: true,
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"]
      },
      password_strength: "",
      reject_weak_password: false,
      custom_weak_passwords: ""
    };
  },
  created() {
    this.getPwdPolicy();
  },
  methods: {
    password_strengthSel(password_strength) {
      this.password_strength = password_strength;
    },
    rejectPwdSel(reject_weak_password) {
      this.reject_weak_password = reject_weak_password;
    },
    getPwdPolicy() {
      if (this.tabData) {
        let {
          password_strength,
          reject_weak_password,
          custom_weak_passwords
        } = this.tabData;
        this.password_strength = password_strength;
        this.reject_weak_password = reject_weak_password;
        this.custom_weak_passwords = custom_weak_passwords;
      }
    },
    modifyPwdPolicy() {
      if (this.$refs.form.validate()) {
        let payload = {
          password_strength: this.password_strength,
          reject_weak_password: this.reject_weak_password,
          custom_weak_passwords: this.custom_weak_passwords
        };

        return this.$http
          .patch(`api/settings/security/password`, payload)
          .delegateTo(api_request)
          .then(() => {
            bus.$emit("refreshSecurity");
            return this.$snackbar.showMessage({
              content: `保存成功`,
              isError: false
            });
          })
          .catch(({ code, message }) => {
            throw `保存失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    }
  },
  watch: {
    tabData() {
      this.getPwdPolicy();
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-subheader {
  color: rgb(44, 62, 80);
}

/deep/.v-input__slot {
  padding-left: 10px;
}
/deep/ .v-label {
  color: rgb(44, 62, 80);
}

/deep/ .v-label {
  font-size: 14px;
}
/deep/ .v-text-field__slot textarea {
  font-size: 14px;
}
</style>
