const authn_lock_data = [
  {
    nameVal: "10分钟",
    num: 600
  },
  {
    nameVal: "30分钟",
    num: 1800
  },
  {
    nameVal: "1小时",
    num: 3600
  },
  {
    nameVal: "4小时",
    num: 14400
  },
  {
    nameVal: "24小时",
    num: 86400
  }
];

const mfa_data = [
  {
    id: "sms",
    name: "短信验证"
  },
  {
    id: "otp",
    name: "OTP Token"
  },
  {
    id: "fido",
    name: "Fido"
  }
];

export { authn_lock_data, mfa_data };
