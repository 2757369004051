<template>
  <div>
    <v-form :model="form" v-model="valid" ref="form">
      <v-row style="border-bottom:none!important;margin-top:0;" align="center">
        <v-col cols="1">
          <v-subheader>系统名称</v-subheader>
        </v-col>

        <v-col cols="4">
          <v-text-field
            rows="1"
            v-model="system_name"
            placeholder="系统名称"
            required
            :rules="rules.notNullRules"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader>托管访问地址</v-subheader>
        </v-col>

        <v-col cols="4">
          <v-text-field
            rows="1"
            v-model="endpoint_url"
            placeholder="托管访问地址"
            persistent-hint
            hint="激活认证方式需要的托管访问地址"
            required
            :rules="rules.regUrl"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" class="ml-0">
        <v-subheader>短信网关配置</v-subheader> &nbsp;
        <v-divider class="ml-3"></v-divider>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader> SMS服务商</v-subheader>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-select
            v-model="serviceProvider"
            :items="serviceProviders"
            required
            :rules="rules.notNullRules"
            @change="serviceChange(serviceProvider)"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader>用户名</v-subheader>
        </v-col>

        <v-col cols="4">
          <v-text-field
            rows="1"
            v-model="username"
            persistent-hint
            required
            :rules="rules.notNullRules"
            @blur="getSmsCount"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader>密钥</v-subheader>
        </v-col>

        <v-col cols="4">
          <v-text-field
            rows="1"
            v-model="password"
            persistent-hint
            required
            :rules="rules.notNullRules"
            @blur="getSmsCount"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader>剩余短信条数</v-subheader>
        </v-col>

        <v-col cols="4">
          <span style="font-size:14px">{{ smsCount }}</span>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader> 短信签名</v-subheader>
        </v-col>
        <v-col cols="4" class="py-0">
          <v-select
            v-model="smsSign"
            :items="smsSigns"
            required
            :rules="rules.notNullRules"
            @change="signsChange(smsSign)"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row align="center" class="ml-0">
        <v-subheader>短信模板</v-subheader> &nbsp;
        <v-divider class="ml-3"></v-divider>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader>下发密码</v-subheader>
        </v-col>

        <v-col cols="4">
          <span style="font-size:14px"
            >您的
            {{ system_name }}
            用户已成功创建，用户名『###用户名###』，初始密码『###密码###』，请妥善保留密码！【{{
              smsSign
            }}】
          </span>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader>重置密码</v-subheader>
        </v-col>

        <v-col cols="4">
          <span style="font-size:14px"
            >管理员已为您重置密码，新密码为『###密码###』，请妥善保管【{{
              smsSign
            }}】
          </span>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader>登录模板</v-subheader>
        </v-col>

        <v-col cols="4">
          <span style="font-size:14px"
            >您正在登录{{ system_name }}，密码
            『###密码###』，一分钟内有效，请勿泄漏 【{{ smsSign }}】</span
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "SetLogin",
  props: ["tabData"],
  data() {
    let regUrl = value => {
      let reg = /(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };

    return {
      valid: true,
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        regUrl: [v => regUrl(v) || "不符合规范"]
      },
      system_name: "",
      endpoint_url: "",
      username: "",
      password: "",
      smsCount: 0,
      serviceProvider: "内置短信网关",
      serviceProviders: ["内置短信网关"],
      smsSign: "WebEXP",
      smsSigns: ["WebEXP", "IAM"]
    };
  },
  created() {
    this.getSmsSet();
  },
  methods: {
    signsChange(smsSign) {
      this.smsSign = smsSign;
    },
    serviceChange(serviceProvider) {
      this.serviceProvider = serviceProvider;
    },
    getSmsSet() {
      if (this.tabData) {
        let {
          sms_config: { username, password },
          endpoint_url,
          sign,
          system_name
        } = this.tabData;
        this.username = username;
        this.password = password;
        this.endpoint_url = endpoint_url;
        this.smsSign = sign ? sign : "WebEXP";
        this.system_name = system_name ? system_name : "";
        if (this.username && this.password) {
          this.getSmsCount();
        }
      }
    },
    modifySmsSet() {
      if (this.$refs.form.validate()) {
        let payload = {
          system_name: this.system_name,
          sign: this.smsSign,
          endpoint_url: this.endpoint_url,
          username: this.username,
          password: this.password
        };

        return this.$http
          .patch(`api/settings/security/sms`, payload)
          .delegateTo(api_request)
          .then(() => {
            return this.$snackbar.showMessage({
              content: `保存成功`,
              isError: false
            });
          })
          .catch(({ code, message }) => {
            throw `保存失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    getSmsCount() {
      if (this.username && this.password) {
        let payload = {
          username: this.username,
          password: this.password
        };
        return this.$http
          .post(`api/settings/sms/message_count`, payload)
          .delegateTo(api_request)
          .then(({ sms }) => {
            this.smsCount = sms;
          })
          .catch(({ code, message }) => {
            throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    }
  },
  watch: {
    tabData() {
      this.getSmsSet();
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-subheader {
  color: rgb(44, 62, 80);
}

/deep/.v-input__slot {
  padding-left: 10px;
}

/deep/ .v-label {
  color: rgb(44, 62, 80);
  font-size: 14px;
}

/deep/ .v-input__slot {
  padding-left: 5px;
  font-size: 14px;
}

/deep/ .v-select__slot {
  font-size: 14px;
}
</style>
