<template>
  <div>
    <v-form :model="form" v-model="valid" ref="form">
      <v-row align="center" class="mt-3" style="border-bottom:none!important;">
        <v-col cols="1"><v-subheader> 会话过期时间</v-subheader></v-col>
        <v-col cols="4" style="margin-left:26px">
          <v-text-field
            rows="1"
            v-model="session_max_lifetime"
            persistent-hint
            suffix="分钟"
            required
            :rules="rules.isIntegerNum"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1"><v-subheader> 关闭浏览器</v-subheader></v-col>
        <v-col cols="6" class="ml-3">
          <v-radio-group
            v-model="session_expire_at_browser_close"
            column
            @change="isBrowserClosse(session_expire_at_browser_close)"
          >
            <v-radio
              label="结束会话，重新打开浏览器访问时需要重新登录"
              value="true"
            ></v-radio>
            <v-radio
              label="不结束会话，按照会话过期时间设置"
              value="false"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1"><v-subheader> 长期不操作系统</v-subheader></v-col>

        <v-col cols="9" class="ml-3" style="position:relative;">
          <v-radio-group v-model="is_over" column @change="isOver(is_over)">
            <v-radio
              class="curradio"
              value="true"
              style="width:24px;"
            ></v-radio>
            <v-radio
              label="不结束会话，按照会话过期时间设置"
              value="false"
            ></v-radio>
          </v-radio-group>
          <div
            style="position:absolute;left:45px;top:6px;"
            class="d-flex align-center curinput"
          >
            <v-text-field
              v-model="max_inactivate_interval"
              required
              :rules="rules.isIntegerNum"
              :disabled="is_over === 'false'"
            ></v-text-field>
            <span>
              分钟不进行系统操作将结束会话，重新打开浏览器访问时需要重新登录
            </span>
          </div>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        dense
        align="center"
        class="mt-3"
        style="border-bottom:none!important;"
      >
        <v-col cols="1">
          <v-subheader> 用户登录限制</v-subheader>
        </v-col>
        <v-col cols="6" style="margin-left:16px">
          <v-checkbox
            v-model="enable_unique_session"
            @change="isUniqueSession(enable_unique_session)"
            label="开启后，用户同一时间只能一个终端登录"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "SetLogin",
  props: ["tabData"],
  data() {
    let isInteger = value => {
      let reg = /^[1-9]\d*$/;
      return reg.test(value);
    };

    return {
      valid: true,
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isIntegerNum: [v => isInteger(v) || "此项必须为正整数"]
      },
      session_expire_at_browser_close: "false",
      is_over: "false",
      session_max_lifetime: "",
      max_inactivate_interval: 60,
      enable_unique_session: false
    };
  },
  created() {
    this.getSessionManage();
  },
  methods: {
    isUniqueSession(enable_unique_session) {
      this.enable_unique_session = enable_unique_session;
    },
    isOver(is_over) {
      this.is_over = is_over;
    },
    isBrowserClosse(session_expire_at_browser_close) {
      this.session_expire_at_browser_close = session_expire_at_browser_close;
    },
    getSessionManage() {
      if (this.tabData) {
        let {
          session_expire_at_browser_close,
          session_max_lifetime,
          max_inactivate_interval,
          enable_unique_session
        } = this.tabData;

        this.session_expire_at_browser_close = JSON.stringify(
          session_expire_at_browser_close
        );
        if (session_max_lifetime) {
          this.session_max_lifetime = session_max_lifetime / 60;
        }

        this.max_inactivate_interval = max_inactivate_interval
          ? max_inactivate_interval / 60
          : 60;

        this.is_over = max_inactivate_interval ? "true" : "false";

        this.enable_unique_session = enable_unique_session;
      }
    },
    modifySessionManage() {
      if (this.$refs.form.validate()) {
        let payload = {
          session_expire_at_browser_close: JSON.parse(
            this.session_expire_at_browser_close
          ),
          enable_unique_session: this.enable_unique_session,
          session_max_lifetime: this.session_max_lifetime * 60,
          max_inactivate_interval:
            this.is_over === "true" ? this.max_inactivate_interval * 60 : null
        };

        return this.$http
          .patch(`api/settings/security/session`, payload)
          .delegateTo(api_request)
          .then(() => {
            bus.$emit("refreshSecurity");
            return this.$snackbar.showMessage({
              content: `保存成功`,
              isError: false
            });
          })
          .catch(({ code, message }) => {
            throw `保存失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    }
  },
  watch: {
    tabData() {
      this.getSessionManage();
    }
  }
};
</script>

<style lang="less" scoped>
.curinput {
  /deep/ .v-text-field input {
    padding: 24px 0 8px !important;
  }
}
/deep/ .v-subheader {
  color: rgb(44, 62, 80);
}

/deep/.v-input__slot {
  padding-left: 10px;
}

/deep/ .v-label {
  font-size: 14px;
}

.curradio {
  /deep/ .v-input--selection-controls__input {
    margin-right: 80px;
  }
}

/deep/ .v-text-field__slot {
  font-size: 14px;
}

/deep/ .v-text-field__slot textarea {
  font-size: 14px;
}

/deep/ .v-label {
  color: rgb(44, 62, 80);
}

/deep/ .v-input__slot {
  padding-left: 5px;
}
</style>
