<template>
  <div>
    <v-form :model="form" v-model="valid" ref="form">
      <v-row
        no-gutters
        dense
        class="mt-3"
        style="border-bottom:none!important;"
        align="center"
      >
        <v-col cols="1">
          <v-subheader> 管理员专用端口</v-subheader>
        </v-col>
        <v-col cols="6">
          <v-checkbox
            v-model="separate_admin_port"
            @change="adminPortSel(separate_admin_port)"
            label="勾选后，管理员角色用户仅可以通过3088端口对管理界面进行访问"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row no-gutters dense style="margin-top:0;" align="center">
        <v-col cols="1">
          <v-subheader> 帐户锁定</v-subheader>
        </v-col>
        <v-col cols="6">
          <v-checkbox
            v-model="enabled_authn_lock"
            @change="authnLockSel(enabled_authn_lock)"
            label="勾选后，帐户登录时，如果一天内连续登录失败次数超过设置的“登录失败次数”，帐户将会被锁定;当帐户被锁定时间超出设置的“帐户锁定时间”，方可重新登录"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="center" v-if="enabled_authn_lock" class="ml-4">
        <v-col cols="1"><v-subheader> 认证失败次数</v-subheader></v-col>
        <v-col cols="4" class="ml-3">
          <v-text-field
            rows="1"
            v-model="authn_failure_count"
            persistent-hint
            hint="一天内，连续认证失败次数一旦超出设置的次数，账户将会锁定"
            required
            :rules="rules.isIntegerNum"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" v-if="enabled_authn_lock" class="ml-4">
        <v-col cols="1"><v-subheader> 帐户锁定时间</v-subheader></v-col>
        <v-col cols="4" class="ml-3">
          <v-select
            v-model="authn_lock_expiration"
            :items="authn_lock_expirations"
            item-text="nameVal"
            item-value="num"
            required
            :rules="rules.notNullRules"
            @change="authnLockTime(authn_lock_expiration)"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row no-gutters dense style="margin-top:0;" align="center">
        <v-col cols="1">
          <v-subheader> 图片验证码</v-subheader>
        </v-col>
        <v-col cols="6">
          <v-checkbox
            v-model="enabled_captcha"
            @change="isCaptcha(enabled_captcha)"
            label="勾选后，帐户登录时，如果一天内连续登录失败次数超过设置的“登录失败次数”，帐户将会被锁定;当帐户被锁定时间超出设置的“帐户锁定时间”，方可重新登录"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="center" v-if="enabled_captcha" class="ml-4">
        <v-col cols="1"><v-subheader> 认证失败次数</v-subheader></v-col>
        <v-col cols="4" class="ml-3">
          <v-text-field
            rows="1"
            v-model="enable_captcha_threshold"
            persistent-hint
            hint="一天内，连续认证失败次数一旦超出设置的次数，开始显示验证码"
            required
            :rules="rules.isIntegerNum"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- 双因素安全验证 -->
      <v-row v-if="0">
        <v-col cols="1">
          <v-subheader> 验证因子项</v-subheader>
        </v-col>
        <v-col cols="4">
          <draggable :list="mfaAuths" handle=".mover">
            <transition-group>
              <div v-for="item in mfaAuths" :key="item.id">
                <v-list-item style="border-bottom:1px solid #e6e6e6">
                  <template v-slot:default="{ active }">
                    <v-btn icon class="mover">
                      <v-icon>mdi-format-line-spacing</v-icon>
                    </v-btn>
                    <div
                      style="width:100%"
                      class="d-flex justify-space-between"
                      @click="checkItem(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="unselect">
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          @change="checkItem(item)"
                          v-model="item.check"
                          :input-value="active"
                          :disabled="is_readonly"
                        ></v-checkbox>
                      </v-list-item-action>
                    </div>
                  </template>
                </v-list-item>
              </div>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import { authn_lock_data, mfa_data } from "../util/base";
import bus from "@/util/eventBus";
import draggable from "vuedraggable";

export default {
  name: "SetLogin",
  props: ["tabData"],
  data() {
    let isInteger = value => {
      let reg = /^[1-9]\d*$/;
      return reg.test(value);
    };
    return {
      valid: true,
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isIntegerNum: [v => isInteger(v) || "此项必须为正整数"]
      },
      enabled_authn_lock: false,
      authn_lock_expiration: "10分钟",
      authn_failure_count: "",
      separate_admin_port: false,
      enabled_captcha: false,
      enable_captcha_threshold: 1,
      authn_lock_expirations: authn_lock_data,
      mfaAuths: mfa_data
    };
  },
  created() {
    this.getLoginSecurity();
  },
  methods: {
    // 双因素身份验证
    checkItem(item) {
      item.check = item.check ? false : true;
    },
    isCaptcha(enabled_captcha) {
      this.enabled_captcha = enabled_captcha;
    },
    adminPortSel(separate_admin_port) {
      this.separate_admin_port = separate_admin_port;
    },
    authnLockSel(enabled_authn_lock) {
      this.enabled_authn_lock = enabled_authn_lock;
    },
    authnLockTime(authn_lock_expiration) {
      this.authn_lock_expiration = authn_lock_expiration;
    },
    getLoginSecurity() {
      if (this.tabData) {
        let {
          enabled_authn_lock,
          authn_lock_expiration,
          authn_failure_count,
          separate_admin_port,
          enabled_captcha,
          enable_captcha_threshold
        } = this.tabData;
        this.enabled_authn_lock = enabled_authn_lock;
        this.authn_lock_expiration = authn_lock_expiration
          ? authn_lock_expiration
          : "10分钟";
        this.authn_failure_count = authn_failure_count;
        this.separate_admin_port = separate_admin_port;
        this.enabled_captcha = enabled_captcha;
        this.enable_captcha_threshold = enable_captcha_threshold
          ? enable_captcha_threshold
          : 1;
      }
    },
    modifyLoginSecurity() {
      if (this.$refs.form.validate()) {
        let payload = {
          enabled_authn_lock: this.enabled_authn_lock,
          separate_admin_port: this.separate_admin_port,
          authn_lock_expiration: this.authn_lock_expiration,
          authn_failure_count: Number(this.authn_failure_count),
          enabled_captcha: this.enabled_captcha,
          enable_captcha_threshold: this.enabled_captcha
            ? Number(this.enable_captcha_threshold)
            : null
        };

        return this.$http
          .patch(`api/settings/security/login`, payload)
          .delegateTo(api_request)
          .then(() => {
            bus.$emit("refreshSecurity");
            return this.$snackbar.showMessage({
              content: `保存成功`,
              isError: false
            });
          })
          .catch(({ code, message }) => {
            throw `保存失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    }
  },
  watch: {
    tabData() {
      this.getLoginSecurity();
    }
  },
  components: {
    draggable
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-subheader {
  color: rgb(44, 62, 80);
}

/deep/ .v-label {
  color: rgb(44, 62, 80);
}

/deep/.v-input__slot {
  padding-left: 10px;
}

/deep/ .v-label {
  font-size: 14px;
}

/deep/ .v-select__slot {
  font-size: 14px;
}

/deep/ .v-text-field__slot {
  font-size: 14px;
}
/deep/ .v-text-field__slot textarea {
  font-size: 14px;
}

/deep/ .v-input__slot {
  padding-left: 5px;
}
</style>
